import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from './ErrorIcon';

const containerStyle = {
	background: 'rgba(0,0,0,0.1)',
	borderColor: 'rgba(0,0,0,0.2) !important'
};
const fontStyle = {
	color: 'rgba(0,0,0,0.9)'
};

const ErrorWrapper = ({ error }) => {
	return (
		<div
			className="d-flex flex-column align-items-center border rounded pt-12 pb-6"
			style={containerStyle}
		>
			<ErrorIcon />
			<h1 className="mb-0" style={fontStyle}>
				Oops! This content is in the shop.
			</h1>
			<h2 className="mt-0" style={fontStyle}>
				We were unable to locate the content you requested.
			</h2>
			<p style={fontStyle}>
				{error.status}:{error.message}
			</p>
		</div>
	);
};

ErrorWrapper.propTypes = {
	error: {
		status: PropTypes.string,
		message: PropTypes.string
	}
};

export default ErrorWrapper;
