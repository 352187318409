import { ParamModifier } from 'wsm-iframe';

export const makeCookieParams = (prefs, sourceUrl, isBrowser) => {
	const cookieParam = prefs.cookieParams;
	if (!cookieParam) {
		return sourceUrl;
	} else if (!isBrowser){
		return 'about:blank';
	}

	const newSourceUrl = new URL(sourceUrl);
	let modifier;
	const { cookieParams, paramModifierSetsToApply = '' } = prefs;
	const cookies = window?.Cookies?.get();

	const paramsToAppend = cookieParams
		.replace(/\s+/g, '')
		.split(',')
		.map(param => {
			const [key, value] = param.split('=');
			const dynamicVal = value || key;
			return (key || value) && (value ? [key, dynamicVal] : [dynamicVal, '']);
		});

	if (paramModifierSetsToApply.trim()) {
		modifier = new ParamModifier(paramModifierSetsToApply);
	}

	for (let i = paramsToAppend.length - 1; i >= 0; i--) {
		const [key, value] = paramsToAppend[i];
		const dynamicParamType = value || key;
		let dynamicParamValue =
			cookies[dynamicParamType] || dynamicParamType;

		// perform parameter modifications
		if (modifier) {
			dynamicParamValue = modifier.apply(
				`cookie:${dynamicParamType}`,
				dynamicParamValue,
				key,
				{ ...prefs },
				cookies
			);
		}

		if (dynamicParamValue.includes('cookie:')) {
			// delete unmatched dynamic parameter (ignore static parameters)
			paramsToAppend.splice(i, 1);
		} else {
			// recreate parameter with updated values
			paramsToAppend[i] = value ? `${key}=${dynamicParamValue}` : dynamicParamValue;
		}
	}

	newSourceUrl.search += `${newSourceUrl.search && '&'}${paramsToAppend.join('&')}`;

	return newSourceUrl.href.replace(/(^\w+:|^)\/\//, '');
};

