import { SET_SOURCE_URL } from '../actions/types';

export default function sourceUrl(state = '', { type, payload, error }) {
	if (!error && payload) {
		switch (type) {
			case SET_SOURCE_URL:
				return payload;
			// no default
			default:
		}
	}

	return state;
}
