import React from 'react';
import PropTypes from 'prop-types';

const warningStyle = {
	width: '3rem',
	height: '3rem',
	fontWeight: 900,
	fontSize: '1.8rem',
	color: 'white'
};

const grey = {
	background: 'rgba(0,0,0,0.6)',
	boxShadow:
		'-1px -1px 0px rgba(0,0,0,0.9), -1px 1px 0px rgba(0,0,0,0.9), 1px -1px 0px rgba(0,0,0,0.9), 1px 1px 0px rgba(0,0,0,0.9)'
};

const color = {
	background: '#ff8787',
	boxShadow:
		'-1px -1px 0px #fa5252, -1px 1px 0px #fa5252, 1px -1px 0px #fa5252, 1px 1px 0px #fa5252'
};

const ErrorIcon = ({ greyscale = true }) => {
	return (
		<div
			className="d-flex align-items-center justify-content-center rounded-circle"
			style={
				greyscale
					? {
							...warningStyle,
							...grey
					  }
					: {
							...warningStyle,
							...color
					  }
			}
		>
			!
		</div>
	);
};

ErrorIcon.propTypes = {
	greyscale: PropTypes.string
};

export default ErrorIcon;
