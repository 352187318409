import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePrefs, useFlags } from 'wsm-common-data';
import { Iframe } from 'wsm-iframe';
import { isBrowser } from 'ws-scripts/modules/environment';
import ErrorWrapper from '../components/ErrorWrapper';
import {FLAG_IFRAME_HTML_SRC_VALIDATION, WIDGET_SERVICE_EXCLUDE_COOKIES} from '../global-constants';
import {makeCookieParams} from '../util/makeCookieParams';

/**
 * A frame container.
 * @param {Object} props The component props.
 * @param {string} props.sourceUrl The frame source URL. This value is parsed,
 * 	encoded and dispatched in the server store.
 */
const WidgetContainer = ({ sourceUrl }) => {
	const prefs = usePrefs();
	const flags = useFlags();
	const shouldValidateSrc = flags[FLAG_IFRAME_HTML_SRC_VALIDATION];
	const widgetServiceExcludeCookies = flags[WIDGET_SERVICE_EXCLUDE_COOKIES];

	if (shouldValidateSrc && sourceUrl.error) {
		return <ErrorWrapper error={sourceUrl.error} />;
	} else {
		return (
			<Iframe
				{...prefs}
				src={widgetServiceExcludeCookies ? makeCookieParams(prefs, sourceUrl, isBrowser) : sourceUrl}
				encode={false}
			/>
		);
	}
};

WidgetContainer.propTypes = {
	sourceUrl: PropTypes.string.isRequired || {
		error: {
			status: PropTypes.string,
			message: PropTypes.string
		}
	},
	setSourceUrl: PropTypes.func
};

const mapStateToProps = (state) => ({ sourceUrl: state.sourceUrl });
const mapDispatchToProps = (_dispatch) => ({});
const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
